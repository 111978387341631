<template>
          <!-- Begin Page Content -->
        <div class="container-fluid">

        <div class="row pb-1 border-bottom border-info mb-5">

          <div class="col">
                <h4 class="font-weight-lighter text-info"> <span class="fa fa-tachometer-alt"></span> Tableau De bord</h4> 
            </div>
          <div class="col">


                           <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="outline-secondary" @click="actualiserDash">
                              <i class="fa fa-sync-alt"></i>
                              <span> Actualiser</span> 
                          </b-button>
            </div>


            
        </div>




          <!-- Content Row -->
          <div v-show="d_gst_col_lait" class="row">

            <!-- Content Column -->
            <div class="col">

              <!-- Project Card Example -->
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <span></span>






                  <h6 class="m-0 font-weight-bold text-info float-left">Lait collecté  </h6>

                 <h6 class="ml-3 font-weight-light text-secondary float-left">De : <span class="d-inline text-info"> {{params.date_debut}} </span> Au : <span class="d-inline text-info"> {{params.date_fin}} </span> </h6>

                        <b-button   class=" ml-2 p-0 float-left text-secondary" variant="link" @click="changDateLai_click">
                        <i class="fa fa-marker"></i>                
                        </b-button>


                  <h6 class="m-0 font-weight-light text-primary float-right">Total : {{total_L}} L</h6>
                </div>
                <div class="card-body">
                        <div>
                          <div v-for="bar in mybars" :key="bar.variant" class="row mb-1">
                            <div class="col-sm-3 font-weight text-secondary h6">{{ bar.cent }} </div>
                            <div class="col-sm-2 font-weight-light text-primary h6">{{ bar.qte }} L</div>
                            <div class="col-sm-7 pt-1">
                              <b-progress :value="bar.value" :variant="bar.variant" height="20px" :max="100" :precision="2" show-value :key="bar.variant"></b-progress>
                            </div>
                          </div>
                        </div>
                </div>
              </div>

              <!-- Color System -->


            </div>

          </div>


            


          <!-- Content Row -->
          <div class="row">
            <!-- Earnings (Monthly) Card Example -->
            <div v-if="paiefourtrd>0 && d_gst_tresor==true" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal text-danger  NonCard mb-1">Paiements Fournisseurs </div>
                      <div class="h5 mb-0  text-secondary">{{paiefourtrd}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-coins fa-2x text-danger"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div v-if="paieclientrd>0 && d_gst_tresor==true" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal text-danger  NonCard mb-1">Paiements Clients </div>
                      <div class="h5 mb-0  text-secondary">{{paieclientrd}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-coins fa-2x text-danger"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                        <!-- Earnings (Monthly) Card Example -->
            <div v-if="paievenrdrd>0 && d_gst_tresor==true" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal text-danger  NonCard mb-1">Paiements Vendeurs </div>
                      <div class="h5 mb-0  text-secondary">{{paievenrdrd}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-coins fa-2x text-danger"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div v-if="lotsexp>0 && d_gst_art==true" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal text-danger  NonCard mb-1">Lots Expirés </div>
                      <div class="h5 mb-0  text-secondary">{{lotsexp}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-layer-group fa-2x text-danger"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- Earnings (Monthly) Card Example -->
            <div v-if="deplacCount>0 && d_gst_deplac==true" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal  NonCard mb-1">Déplacements </div>
                      <div class="h5 mb-0  text-secondary">{{deplacCount}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-cubes fa-2x BgNotifIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div v-if="entreCount>0 && d_gst_entre==true" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6  font-weight-normal  NonCard mb-1">Entrées </div>
                      <div class="h5 mb-0  text-secondary">{{entreCount}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-boxes fa-2x BgNotifIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div v-if="sortiCount>0 && d_gst_sorti==true" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal  NonCard mb-1">Sorties </div>
                      <div class="h5 mb-0  text-secondary">{{sortiCount}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-box fa-2x BgNotifIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                        <!-- Earnings (Monthly) Card Example -->
            <div v-if="livrCount>0 && d_gst_livrais==true" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal  NonCard mb-1">Livraisons </div>
                      <div class="h5 mb-0  text-secondary">{{livrCount}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-file-download fa-2x BgNotifIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                        <!-- Earnings (Monthly) Card Example -->
            <div v-if="avoirCount>0 && d_gst_avoir==true" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal  NonCard mb-1">Avoirs </div>
                      <div class="h5 mb-0 text-secondary">{{avoirCount}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-file-upload fa-2x BgNotifIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                        <!-- Earnings (Monthly) Card Example -->
            <div v-if="primCount>0 && d_gst_prim==true" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal  NonCard mb-1">Primes Clients </div>
                      <div class="h5 mb-0  text-secondary">{{primCount}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-inbox fa-2x BgNotifIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                        <!-- Earnings (Monthly) Card Example -->
            <div v-if="bnchrgCount>0 && d_gst_charg" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal  NonCard mb-1">Charges </div>
                      <div class="h5 mb-0  text-secondary">{{bnchrgCount}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-people-carry fa-2x BgNotifIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                        <!-- Earnings (Monthly) Card Example -->
            <div v-if="depcmd>0 && d_gst_suivi_cmd_dep" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal  NonCard mb-1">Commandes Dépôt </div>
                      <div class="h5 mb-0  text-secondary">{{depcmd}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-file-download fa-2x BgNotifIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


                        <!-- Earnings (Monthly) Card Example -->
            <div v-if="prodwork>0 && d_gst_prod_prod" class="col-xl-3 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="h6 font-weight-normal  NonCard mb-1">Productions </div>
                      <div class="h5 mb-0  text-secondary">{{prodwork}}</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-cogs fa-2x BgNotifIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!-- Content Row -->





    <!-- le modal modifier date-->
    <b-modal id="DatesModal" title="Dates"  hide-footer>
           <form @submit.prevent="fetchLaitCollecte">

            <div class="form-group col">
                <label for="dateDebut" class="mr-sm-2">Date Début :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-dateDebut"
                    placeholder="date debut"
                    v-model="params.date_debut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>

            <div class="form-group col">
                <label for="dateFin" class="mr-sm-2">Date Fin :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="params.date_fin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>


        </form>
    </b-modal>


    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


        </div>
        <!-- /.container-fluid -->

  
      <!-- End of Main Content -->

</template>


<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
    computed: {
          user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
          d_gst_col_lait() {
      return this.$store.getters.get_gst_col_lait;
    },

    //stock

    d_gst_art() {
      return this.$store.getters.get_gst_art;
    },
    d_gst_deplac() {
      return this.$store.getters.get_gst_deplac;
    },

    d_gst_entre() {
      return this.$store.getters.get_gst_entre;
    },

    d_gst_sorti() {
      return this.$store.getters.get_gst_sorti;
    },
        d_gst_suivi_cmd_dep() {
      return this.$store.getters.get_gst_suivi_cmd_dep;
    },

    //achat :
    d_gst_fournis() {
      return this.$store.getters.get_gst_fournis;
    },
    d_gst_cmd() {
      return this.$store.getters.get_gst_cmd;
    },
    d_gst_livrais() {
      return this.$store.getters.get_gst_livrais;
    },

    d_gst_avoir() {
      return this.$store.getters.get_gst_avoir;
    },

      //tresor :

    d_gst_tresor() {
      return this.$store.getters.get_gst_tresor;
    },

        //charg / decharge :

    d_gst_prim() {
      return this.$store.getters.get_gst_prim;
    },
    d_gst_charg() {
      return this.$store.getters.get_gst_charg;
    },
    //prod
    d_gst_prod_prod() {
      return this.$store.getters.get_gst_prod_prod;
    },
    },
    mounted(){
        this.fetchList()
        this.fetchLaitCollecte()
    },
	data () {
		return {

            deplacCount: 0,
            entreCount: 0,
            sortiCount: 0,
            livrCount: 0,
            avoirCount:0,
            primCount: 0,
            bnchrgCount: 0,
            lotsexp:0,
            paiefourtrd:0,
            paieclientrd: 0,
            paievenrdrd: 0,
            depcmd: 0,
            prodwork:0,
total_L: 0,
        mybars:[],


                    params: {
                    date_debut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    date_fin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),

                    },

                    date_today:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            //public trucs

            isLoading: false
		}
	},
    methods :{

        changDateLai_click()
        {
this.$root.$emit('bv::show::modal', 'DatesModal')
        },
        fetchList: function () {



                    this.isLoading=true;
                    this.$http.get('/dashBordCount/'+this.date_today+'/'+this.user_dep)
                    .then((result) => {
                                this.deplacCount = result.data.dep
                                this.entreCount = result.data.ent
                                this.sortiCount = result.data.sort
                                this.livrCount = result.data.livr
                                this.avoirCount=result.data.avr
                                this.primCount = result.data.prim
                                this.bnchrgCount = result.data.bnchrg
                                this.lotsexp = result.data.lotsexp
                                this.paiefourtrd = result.data.paiefourtrd
                                this.paieclientrd = result.data.paieclientrd
                                this.paievenrdrd = result.data.paievenrdrd
                                this.depcmd = result.data.depcmd
                                this.prodwork = result.data.prodwork
                                
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        fetchLaitCollecte()
        {
if(this.d_gst_col_lait==true)
{
                      this.isLoading=true;
                    this.$http.get('/laitDachBord/'+this.params.date_debut+'/'+this.params.date_fin)
                    .then((result) => {

                              //cacher le truc:
                              this.$bvModal.hide('DatesModal')


                                                // console.log(result.data.data) 
                                                  let myArray = [];
                                                  let pourc = 0;
                                                  let totl = result.data.data.reduce((a, b) => +a + +b.total_qte, 0);

                                                  if(totl>0)
                                                  {
                                                                  result.data.data.forEach(function(item) {

                                                                    pourc = ((item.total_qte/totl)*100).toFixed(2)

                              if(pourc<20)
                              {
                              myArray.unshift( {'variant': 'danger', 'value': pourc, 'cent':item.nom , 'qte':item.total_qte})
                              }
                              else if (pourc<50)
                              {
                              myArray.unshift( {'variant': 'info', 'value': pourc,  'cent':item.nom, 'qte':item.total_qte})

                              }
                              else
                              {
                              myArray.unshift( {'variant': 'success', 'value': pourc,  'cent':item.nom, 'qte':item.total_qte})

                              }
                                                                    

                                    }); 
                    }

                   this.total_L= totl;            

                    this.mybars=myArray;
                    

                             //   console.log(this.mybars);
                             //    console.log(totl);
                              //   console.log(this.bars);

                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
}
          


        },
        actualiserDash()
        {
        this.fetchList()
        this.fetchLaitCollecte()
        }


    },
    components: {
              Loading      
    }
}
</script>


<style>
.BgNotifIcon {
  color: #ff793f;
  opacity: 0.9;
}
.NonCard {
  color: #d35400;
  opacity: 0.9;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>