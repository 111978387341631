<template>
     <div class="vue-tempalte">


<div class="col-10 inner-block mt-5 ">




<div class=" d-flex justify-content-center text-info mb-4">
 <h2 >
AM
   <span class="font-weight-lighter">   Cooperative Manager <span class="h6 font-weight-lighter">v2.6</span></span>
</h2>
</div>


<div class="row">

<div class="col-xl-6 col-md-6">

<img alt="Vue logo" src="../../assets/login.png" class="col">
</div>

<div class="col-xl-6 col-md-6">



        <form  @submit.prevent="handleSubmit" class="mt-5">

            <div class="form-group">

                <input type="text" class="form-control form-control-lg rounded-pill border-info" v-model="email" placeholder="Login" />
            </div>

            <div class="form-group">

                <input type="password" class="form-control form-control-lg rounded-pill border-info" v-model="password" placeholder="Mot De Passe" />
            </div>
            <div style="color:red;" v-if="error">{{ error }}</div>

            <button type="submit" class="btn btn-info btn-lg btn-block rounded-pill mt-4">Connexion</button>
        </form>
</div>

</div>












</div>

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {

  
  data() {
    return {
      email: "",
      password: "",
      error: "",
      isLoading: false
    };
  },
  methods:{

        handleSubmit() {
              /*  this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                } loadDroitListX  */

      this.isLoading = true;
      this.$store
        .dispatch("performLoginAction", {
          email: this.email,
          password: this.password
        })
        .then(res => {
          this.isLoading = false;

          this.loadDroits(res.data.user.id)

        })
        .catch(err => {
          this.isLoading = false;
          this.error = " Entrez un login et un mot de passe valide !!";
          console.log(err.message);
        });








            },
            
        loadDroits(user_i) {


      this.isLoading = true;
      this.$store
        .dispatch("loadDroitListX", {
          user_id: user_i,
        })
        .then(res => {
          this.isLoading = false;


          console.log(res);

                    this.$router.push({ 
                        name: 'Home'
                    });
        })
        .catch(err => {
          this.isLoading = false;
          this.error = " Une erreur s'est produite lors du processus de connexion";
          console.log(err.message);
        });








            }

  },
      components: {
              Loading      
    }

}
</script>

<style scoped>

.vue-tempalte,
.vertical-center {
  width: 100%;
  height: 100%;
}


.vertical-center {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;    
}

.inner-block {

  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.vertical-center .form-control:focus {
  border-color: #2554FF;
  box-shadow: none;
}

.vertical-center h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

label {
  font-weight: 500;
}


.userpic {
  width: 300px;
}


</style>